import ko from "knockout";
// import jQuery from "jquery";

ko.options.useOnlyNativeEvents = true;

import ResizeObserver from "resize-observer-polyfill";
if(!window.ResizeObserver)
  window.ResizeObserver = ResizeObserver;
import "element-remove";
import 'elm-pep'; //pointer event polyfill iOS 12
// import "popper.js";
// import "bootstrap/dist/js/bootstrap";

import {Dropdown, Tooltip} from "bootstrap"

import "chart.js"

import "./js/baseConfig";
import('./js/main');