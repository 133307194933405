import MessageHandler from "./MessageHandler";
import { xsrfTokenHeaderName, xsrfTokenValue } from "./xsrfSynchronizerToken";

export let API_BASE = "";

let maxFileSizeString = "25 MByte";

/** imports sind imme readonly, deswegen brauchen wir einen separaten Setter */
export function setAPI_BASE(val) {
  API_BASE = val;
}

export function setMaxFileSizeString(val) {
  maxFileSizeString = val;
}

let defaultMessageHandler = MessageHandler;
/**
 * 
 * @param {import("./MessageHandler").MessageHandler} msgHandler 
 */
export function setMessageHandler( msgHandler) {
  defaultMessageHandler = msgHandler;
}

/** @type {(url: string, options?: RequestInit, silent?: boolean) => Promise<Response>} */
export default async function apifetch(url, options = null, silent = false) {
  let base = "";
  let fetchOpts = options;

  //absolute urls nicht mit API_BASE prefixen
  let regex = /(^.+:\/\/)|(^\/)/;
  if (!regex.test(url)) {
    base = API_BASE
  }


  if (xsrfTokenValue) {
    if (options && options.method) {
      if (["POST", "PUT", "PATCH", "DELETE"].includes(options.method.toUpperCase())) {
        if (!options.headers) {
          options.headers = {};
        }
        options.headers[xsrfTokenHeaderName] = xsrfTokenValue;
      }
    }
    // keine Opts, dann auch kein POST etc.
  }

  let resp = await fetch( base + url, fetchOpts);
    /* fetch wirft nur eine Expcetion, wenn eine Netzwerkfehler aufgetreten ist ...
      In der Regel ist es nützlicher, wenn bei einem nicht Okay-ischen  Response Code eine Exception geworfen wird.
    */
  if (!resp.ok && !resp.redirected) {
    let reloadPage = false;
    let err = "Es ist ein Fehler aufgetreten."
    if (resp.status === 403) {
      err = "<b>403</b>: Zugriff verweigert."
    }
    else if (resp.status === 413) {
      err = `<b>413</b>: maximale Dateigröße von ${maxFileSizeString} überschritten.`
    }
    else {
      try {
        try {
          let json = await resp.json();
          err = json.text;
          err = `<b>${json.statusText}</b>: ${json.text}`;
          reloadPage = json.promptPageReload;
        } catch (e) {
          err = await resp.text();
          console.error(e);
        }
      }
      catch (e) { }
    }
    if(!silent)
      defaultMessageHandler.error(err, reloadPage);
    throw new Error(err);
  }
  return resp;
}

/** @type {(url: string, options?: RequestInit) => Promise<Response>} */
export async function silentapifetch(url, options){
  return apifetch(url, options, true);
}