<template>
  <div v-html="message"></div>
  <form @submit.prevent="resolve(pwd)">
    <p>
      <PasswordInput v-model="pwd"/>
    </p>
    <div>
      <button type="submit">Durchführen</button>
      <button type="reset" @click="resolve(false)">Schließen</button>
    </div>
  </form>
</template>

<script>
import { ref } from 'vue';
import PasswordInput from '../base/PasswordInput.vue';


export default {
  components: { PasswordInput },
  props: {
      resolve: Function,
      reject: Function,
      message: {
          type: String,
          default: "Um die Änderungen durchzuführen müssen Sie ihr Passwort erneut eingeben."
      }
  },
  inheritAttrs: false,
  setup(props) {
      let pwd = ref("");
      return {
          pwd
      };
  },
}
</script>

<style>

</style>