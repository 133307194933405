import { v4 as generateRandomUuid } from "uuid";
import { setAPI_BASE, setMessageHandler, API_BASE } from "vueforms/src/utils/apifetch";
import { MessageHandler } from "vueforms/src/utils/MessageHandler";

import { notifications, LEVEL } from "mapview-gui-utils/src/notification/Notifications"
import NotificationComponent from "mapview-gui-utils/src/notification/Notification.vue"
import Windows from "vueforms/src/components/windows/Windows.vue"
import {defineCustomElement } from  "mapview-gui-utils/src/apiCustomElement"

let baseURL = __DATA_LOCATION_URL__;
let metaLocation = "";
let commentSessionId = localStorage.getItem("commentSessionId");

let customElem = defineCustomElement(NotificationComponent, { shadowRoot: false });
customElements.define("hdtc-notifications", customElem)

let WindowsElem = defineCustomElement(Windows, { shadowRoot: false });
customElements.define("hdtc-windows", WindowsElem);


if (window.location.search) {
  let args = window.location.search.substr(1).split("&");
  args.forEach((arg) => {
    let param = arg.split("=");
    if (param[0] === "url"){
      baseURL = decodeURIComponent(param[1]);
    }
    else if (param[0] === "commentSessionId") {
      commentSessionId = param[1];
      localStorage.setItem("commentSessionId", commentSessionId);
    }
  });
}

if (!commentSessionId) {
  commentSessionId = generateRandomUuid();
  localStorage.setItem("commentSessionId", commentSessionId);
}

metaLocation = baseURL;

// setAPI_BASE(baseURL);

class MapViewMessageHandler extends MessageHandler{
  error(msg) {
    notifications.addMessage(msg, LEVEL.ERROR);
    console.error(msg);
  }
  warn(msg) {
    notifications.addMessage(msg, LEVEL.WARNING);
    console.warn(msg);
  }

  info(msg) {
    notifications.addMessage(msg, LEVEL.INFO);
    console.info(msg);
  }
}

setMessageHandler(new MapViewMessageHandler());


export function getBaseURL() {
  return baseURL;
}

export function getMetaLocation() {
  return metaLocation;
}

export function getCommentSessionId() {
  return commentSessionId;
}