<template>
  <div class="querybychoice">
    <div v-html="message"></div>
    <br/>
    <div class="buttonbox">
      <template v-for="(choice) in buttons">
        <button class="choicebutton" @click="resolve(choice[1])">{{choice[0]}}</button>
      </template>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    resolve: Function,
    message: String,
    choices: {
      type: Array,
      default() { return [["Ja", true], ["Nein", false]] }
    }
  },  
  inheritAttrs: false,
  setup(props) {
    let buttons = props.choices.map(e => {
      if (e instanceof Array) {
        return e
      }
      else {
        return [e, e]
      }
    })
    return {
      buttons
    }
  }
}
</script>

<style>

</style>