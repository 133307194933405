<template>
<input :type="showKlartext ? 'text' : 'password'"  :value="val"
    @input="update" v-bind="$attrs"/><span tabindex="0" :class="['vueforms-icon-pw',cssclass]" title="Sichtbarkeit umschalten" @click="showKlartext = ! showKlartext"></span>
</template>
<script>
import { computed, ref, watchEffect } from 'vue';

export default {
  props: {
    modelValue: {
      type: String,
      required: false
    }
  },
  emits: ["update:modelValue"],
  inheritAttrs: false,
  setup(props, {emit}) {
    let showKlartext = ref(false);
    let cssclass = computed(() => showKlartext.value ? 'vueforms-icon-showpw' : 'vueforms-icon-hidepw')
    let val = ref(props.modelValue);

    watchEffect(() => {
      if(props.modelValue != undefined) 
        val.value = props.modelValue;
    });

    return {
      val,
      cssclass,
      showKlartext,
      update(event) {
        val.value = event.target.value;
        if(props.modelValue != undefined) 
          emit('update:modelValue', event.target.value)
      }

    }
  }

  }

</script>

<style>
.vueforms-icon-pw{
  cursor: pointer;
}
</style>