<template>

  <div v-if="notifications.messages.length > 0" class="notification" style="position: fixed; left: 50%; transform: translate(-50%, 0); max-height: 90%;">
    <template v-for="notification of notifications.messages">
      <div class="cut" :class="notification.level" role="alert" style="display: flex;">
        <div class="text" v-html="notification.text"></div>
        <div @click="toggleCut" style="user-select: none;">
          <span class="up">
            <i class="fas fa-caret-up"></i>
          </span>
          <span class="down">
              <i class="fas fa-caret-down"></i>
          </span>
        </div>
        <div @click="notifications.remove(notification)" style="user-select: none;"><i class="fas fa-times"></i></div>
      </div>
    </template>
  </div>

</template>

<script lang="ts">
import { notifications as notifications} from "./Notifications"
export default {
  setup() {
    return {
      notifications,
      toggleCut: (event : MouseEvent) => {
        const target = event.currentTarget as Node;
        target.parentElement?.classList.toggle("cut");
      }
    }
  }
}
</script>

<style>
.notification {
  border: 1px solid black;
  border-radius: 5px;
  background-color: white;
  box-shadow: 0 0 5px black;
  overflow: hidden;
  width: 90%;
  max-width: 800px;
  z-index: 2;
}

.messageItem {
  display: flex;
}

.messageItem > div {
  padding: 5px 10px;
}

.messageItem > div:hover{
  background-color: rgba(255,255,255,0.3);
  cursor: pointer;
}

.messageItem > .text{
  flex: 1 0 0;
}

.messageItem > div > .down {
  display: none;
}

.messageItem > div > .up {
  display: inline;
}

.messageItem.cut > div > .up {
  display: none;
}

.messageItem.cut > div > .down {
  display: inline;
}

.messageItem.cut > .text{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: initial;
}
</style>