import { shallowReactive } from "vue"
import EnterPassword from "../components/windows/EnterPassword.vue";
import MessageComponent from "../components/windows/Message.vue";
import QueryByChoice from "../components/windows/QueryByChoice.vue";

function* idGenerator() {
  let count = 0;
  while (true) {
    yield count++;
  }
}

const idGen = idGenerator();

class WindowManager{
  constructor() {
    this.windowStack = shallowReactive([]);
    
  }

  async window(component, message, options, props) {
    let resolve, reject;
    const promise = new Promise((rslv, rjct) => {
      resolve = rslv;
      reject = rjct;
    });

    if (typeof options === "string") {
      options = {
        heading: options
      };
    }

    const windowDef = {
      ...props,
      id: idGen.next(),
      message,
      resolve,
      reject,
      component: component,
      options
    }
    this.windowStack.push(windowDef);
    return promise.finally(() => {
      const index = this.windowStack.indexOf(windowDef);
      if(index !== -1)
        this.windowStack.splice(index, 1)
    });
  }

  controlableWindow(component, message, options, props) {
    let resolve, reject;
    const promise = new Promise((rslv, rjct) => {
      resolve = rslv;
      reject = rjct;
    });
    
    if (typeof options === "string") {
      options = {
        heading: options
      };
    }

    const windowDef = {
      ...props,
      id: idGen.next(),
      message,
      resolve,
      reject,
      component: component,
      options
    }
    this.windowStack.push(windowDef);
    promise.finally(() => {
      const index = this.windowStack.indexOf(windowDef);
      if(index !== -1)
        this.windowStack.splice(index, 1)
    });
    return windowDef;
  }

  async message(message, heading, reloadOnClose = false) {
    const prom = this.window(MessageComponent, message, heading);
    if (reloadOnClose) {
      prom.then(e => window.location.reload());
    }
    return prom;
  }

  async yesNo(message, heading) {
    return this.window(QueryByChoice, message, heading);
  }

  /**
   * Öffnet ein Fenster mit einer Frage (Message) und Buttons zur Reaktion auf die Frage.
   * Beispiel:
   *   const doLoeschen = await WindowManager.queryByChoice(["Datensatz löschen", "Abbrechen],"Soll der Datensatz gelöscht werden? ","Löschen?");
   *   if (doLoeschen === "Datensatz löschen") {
   *     ... Löschen ...
   *   }
   * @param {Array<[string, any] | string>} choices: Array mit Strings. Die angebotenen Buttons werden mit diesen Strings beschriftet.
   * @param {string} message: Frage oberhalb der Buttons
   * @param {string} heading: Fenstertitel
   * @returns {Promise<any>} 
   */
  async queryByChoice(choices,message, heading) {
    return this.window(QueryByChoice, message, heading,{choices: choices});
  }

  async enterPassword(message, heading) {
    return this.window(EnterPassword, message, heading);
  }

  block(message, heading) {
    return this.controlableWindow(() => message, null, heading)
  }

}

export default new WindowManager();