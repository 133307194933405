import { getCookieValue } from "./cookies";


export let xsrfTokenValue = getCookieValue("XSRF-TOKEN");
export let xsrfTokenHeaderName = "X-XSRF-TOKEN";
export let xsrfTokenParameterName = "_csrf";

/**
 * @typedef {{
 *  XSRF_COOKIE_NAME?: string; 
 *  xsrfTokenHeaderName?: string; 
 *  xsrfTokenParameterName?: string}} tokenArgs
 */

/**
 * 
 * @param {tokenArgs} tokenArgs
 */
export function setXSRF(tokenArgs) {
  if (tokenArgs.XSRF_COOKIE_NAME) {
    xsrfTokenValue = getCookieValue(tokenArgs.XSRF_COOKIE_NAME);
  }
  else if (tokenArgs.xsrfTokenHeaderName) {
    xsrfTokenHeaderName = tokenArgs.xsrfTokenHeaderName
  }
  else if (tokenArgs.xsrfTokenParameterName) {
    xsrfTokenParameterName = tokenArgs.xsrfTokenParameterName
  }
}

export function setXSRFTokenValue(val) {
  xsrfTokenValue = val;
}