<template>
  <ModalWindow v-for="window in windowManager.windowStack" :key="window.id" :show="true" @update:show="rejectOnClose($event, window)" v-bind="window.options">
    <component :is="window.component" v-bind="window"/>
  </ModalWindow>
</template>

<script>
import windowManager from "../../utils/WindowManager";
import ModalWindow from "../base/ModalWindow.vue";
export default {
  components: {
    ModalWindow
  },
  setup(){
    function rejectOnClose(event, window){
      if(!event.value){
        window.reject();
      }
    }
    return {
      windowManager,
      rejectOnClose
    }
  }
}
</script>

<style>

</style>