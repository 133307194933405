<template>
  <div v-html="message"></div>
  <br/>
  <button @click="resolve(false)">Schließen</button>
</template>

<script>

export default {
  props: [ "resolve", "reject", "message"],
  inheritAttrs: false,
  setup(props){
    return {};
  }
}
</script>

<style>

</style>