import windowManager from "./WindowManager"


export class MessageHandler{
  constructor() {

  }
  error(msg, reloadPage = false) {
    console.error(msg);
    windowManager.message(msg, "Fehler!", reloadPage);
  }
  warn(msg) {
    console.warn(msg)
  }
  info(msg) {
    console.info(msg)
  }
}

export default new MessageHandler();