import { unref } from "vue";
/**
Helfer fr DraggableWindow und ModalWindow
 */
 
let maximalZValue = 1000;

export function drawZValue(){
  return maximalZValue++;
}

export default function bringToTop(ref2DomElement) {
  let el = unref(ref2DomElement);
   if (el) {
      maximalZValue++;
      el.style.zIndex = maximalZValue;
    }       
}