import { reactive } from "vue";

export enum LEVEL  {
  INFO = "messageItem alert alert-primary",
  WARNING = "messageItem alert alert-warning",
  ERROR = "messageItem alert alert-danger"
}

interface NotificationMessage {
  text: string | Error,
  level: LEVEL
}

class Notification {
  messages = reactive<NotificationMessage[]>([]);

  static LEVEL = LEVEL;

  constructor() {
  }

  addMessage(text: string | Error, level = LEVEL.ERROR) {
    const message = { text: text, level: level }
    this.messages.push(message);
    return message;
  }

  addMessageTimed(text: string | Error, level = LEVEL.ERROR, timeout = 2000) {
    const message = this.addMessage(text, level);
    setTimeout(() => {
      const idx = this.messages.indexOf(message);
      if (idx != -1) {
        this.messages.splice(idx, 1);
      }
    }, timeout);
  }

  remove(data : NotificationMessage) {
    let idx = this.messages.indexOf(data);
    this.messages.splice(idx, 1);
  }

}




export let notifications = new Notification();
